<script>
  export let primary = false;
</script>

<style>
  .lds-dual-ring {
    display: inline-block;
    width: 1em;
    height: 1em;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  .lds-dual-ring.primary:after {
    border: 3px solid #f57224;
    border-color: #f57224 transparent #f57224 transparent;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<div class="loading lds-dual-ring" class:primary />
